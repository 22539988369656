import * as React from "react";
import { Achievement } from "../types/careers";
import { Post } from "../components/careers/recent-post";
import { OurValuesProps } from "../components/careers/our-value";
import Image from "../components/image";
import StockOptionsIcon from "../assets/svgs/careers/stock-options.svg";
import HealthCoverageIcon from "../assets/svgs/careers/health-coverage.svg";
import UnlimitedPto from "../assets/svgs/careers/unlimited-pto.svg";
import ParentalLeaveIcon from "../assets/svgs/careers/parental-leave.svg";
import LearningAndDevelopmentIcon from "../assets/svgs/careers/learning-and-development.svg";
import LunchAndSnacksIcon from "../assets/svgs/careers/lunch-and-snacks.svg";
import SocialActivitiesIcon from "../assets/svgs/careers/social-activities.svg";
import Four0OneKIcon from "../assets/svgs/careers/401k.svg";

export const achievements: Achievement[] = [
  {
    title: "$55m",
    text: "in Funding from esteemed VC’s",
  },
  {
    title: "110",
    text: "Employees",
  },
  {
    title: "3.5+ Billion",
    text: "Sessions captured",
  },
  {
    title: "2,400+",
    text: "Customers",
  },
];

export const valuesList: OurValuesProps[] = [
  {
    name: "First principles thinking",
    description:
      "We first seek to understand the root of what we are trying to accomplish before we build a solution. What everyone else is doing doesn’t matter if we don’t understand and address the “why”",
  },
  {
    name: "Low ego",
    description:
      "We allow the best ideas to rise to the surface, regardless of who or where they came from. We actively listen, we ask for advice, and we know creating solutions that solve real problems is what really matters, not who gets the credit ",
  },
  {
    name: "Empathy",
    description:
      "The perspective of others is the foundation of what we do and why. Putting ourselves in other people’s shoes is how we deliver the best experience, whether it’s for a customer, prospect, or colleague",
  },
  {
    name: "Curiosity",
    description:
      "We want to know more - we are inquisitive, we are hungry, and we want to grow, both as individuals and as a company. We document and share our knowledge with others, and seek out answers to what we don’t know",
  },
  {
    name: "Focus",
    description:
      "We have a lot of important problems to tackle, but we focus on just one piece at a time. If a problem is too large, we break it down into smaller pieces to produce the most impact ",
  },
];

export const posts: Post[] = [
  {
    image: <Image src="recent-post-1.png" alt="Recent Post" />,
    title: "Using localStorage with React Hooks",
    excerpt:
      "localStorage is one of the two mechanisms of a browser’s web storage. It allows users to save data as key-value pairs...",
    slug: "using-localstorage-react-hooks",
  },
  {
    image: <Image src="recent-post-2.png" alt="Recent Post" />,
    title: "Rethinking frontend APM",
    excerpt:
      "The center of gravity of the modern application stack is shifting toward the frontend. Demand for rich and performant user interfaces...",
    slug: "rethinking-frontend-apm",
  },
  {
    image: <Image src="recent-post-3.png" alt="Recent Post" />,
    title: "The best new Vue 3 UI libraries of 2021",
    excerpt:
      "This article is a follow-up to my previous article showcasing a list of nine superb Vue 3 UI frameworks. These frameworks...",
    slug: "best-new-vue-3-ui-libraries-2021",
  },
];

export const igniteFeatures = [
  {
    icon: StockOptionsIcon,
    title: "Stock Options",
    text: "Full-time employees receive stock options - we believe that we all should own a piece of the incredible company we’re building.",
  },
  {
    icon: HealthCoverageIcon,
    title: "Health, Dental & Vision Coverage",
    text: "Our robust nationwide health plans give you the flexibility to select the right coverage for you and your family through a variety of plan options.",
  },
  {
    icon: UnlimitedPto,
    title: "Unlimited PTO",
    text: "Enjoy time to travel, plan a staycation, or just take a day off when needed with our unlimited PTO policy. Each employee is afforded the flexibility to rest and recharge.",
  },
  {
    icon: ParentalLeaveIcon,
    title: "Parental Leave",
    text: "Balancing work and family is essential, so LogRocket offers three months of fully-paid parental leave to any employee welcoming a child into their home.",
  },
  {
    icon: LearningAndDevelopmentIcon,
    title: "Learning & Development",
    text: "We know how important career growth is, so we offer a yearly stipend to help support continued development throughout your journey.",
  },
  {
    icon: LunchAndSnacksIcon,
    title: "Lunch & Snacks",
    text: "Free catered lunches three days a week plus unlimited snacks and cold brew in our office.",
  },
  {
    icon: SocialActivitiesIcon,
    title: "Social Activities",
    text: "From trivia to chocolate making to virtual paint nights, we ensure employees are fostering connections and building strong relationships.",
  },
  {
    icon: Four0OneKIcon,
    title: "401(K)",
    text: "Plan and invest for the future with a 401(k) via Guideline. LogRocket’s 401(k) plan provides you an opportunity to defer compensation for your long-term savings.",
  },
];
