import * as React from "react";
import styled from "@emotion/styled";
import Section from "../section";
import { valuesList } from "../../contents/careers";
import OurValue, { OurValuesProps } from "./our-value";
import { breakpoints } from "../../styles/pages/breakpoints";

const StyledOurValues = styled.div`
  h2 {
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #754ABC;
  }
  .container{
    background: #FFFFFF;
    box-shadow: 4.00024px 4.00024px 20.0012px rgb(0 0 0 / 10%);
    padding: 80px 0px;
  }
  .our-values {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap; 
    padding: 40px 20px;
  }
  .team-members {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

    @media (max-width: 1199px) {
      justify-content: space-around;
    }

    & > * {
      margin-top: var(--medium);

      @media (max-width: ${breakpoints["2xs"]}) {
        margin-top: var(--xx-small);
      }
    }
  }
`;

const OurValues = () => (
  <Section style={{ textAlign: "left", background: "#F7F8FC" }}>
    <StyledOurValues>
      <div className="container">
        <h2>Our values</h2>
        <div className="our-values">
          {valuesList.map((t: OurValuesProps) => (
            <OurValue key={t.name} {...t} />
          ))}
        </div>
      </div>
    </StyledOurValues>
  </Section>
);

export default OurValues;
