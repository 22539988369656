import * as React from "react";

import styled from "@emotion/styled";

const StyledOurValue = styled.div`
  width: 370px;
  text-align: left;

  h3 {
    margin-bottom: var(--nano);
  }

  .job-title {
    font-weight: 800;
    color: var(--dark-blue);
  }
`;

export interface OurValuesProps {
  name: string;
  description: string;
}

const OurValue = ({
  name,
  description,
}: OurValuesProps) => (
  <StyledOurValue>
    <h3 className="h3">{name}</h3>
    <p>{description}</p>
  </StyledOurValue>
);

export default OurValue;
