import * as React from "react";

import styled from "@emotion/styled";
import { breakpoints } from "../../styles/pages/breakpoints";

const StyledRecentPost = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 368px;
  margin-top: var(--medium);
  border-radius: 0;
  background: #FFFFFF;
  box-shadow: 1.80861px 1.80861px 9.04304px rgba(0, 0, 0, 0.101961);

  @media (max-width: ${breakpoints.sm}) {
    margin-top: var(--x-small);
  }

  img {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--small);

    & > div > * {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  h3 {
    color: var(--purple);
    font-weight: 700;
    margin-bottom: 1rem !important;
  }
  }

  .subtitle {
    font-weight: 400;
    font-size: var(--tag-line);
    color: black;
    letter-spacing: 0.1em;
  }
`;

export interface Post {
  image: JSX.Element;
  title: string;
  excerpt: string;
  slug: string;
}

const RecentPost = ({ image, title, excerpt, slug }: Post) => (
  <StyledRecentPost>
    <div>{image}</div>
    <div className="text">
      <div>
        <div className="subtitle">LOGROCKET BLOG</div>
        <h3 className="h4">{title}</h3>
        <p>{excerpt}</p>
      </div>
      <a href={`https://blog.logrocket.com/${slug}`} className='plain mt-4'>Read Now
      <i className='arrow right-side' /></a>
    </div>
  </StyledRecentPost>
);

export default RecentPost;
