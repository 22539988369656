import React from "react";
import styled from "@emotion/styled";
import PropTypes from 'prop-types';
import Role from "./role";
import { Role as RoleType } from "../../types/careers";
import { breakpoints } from "../../styles/pages/breakpoints";

const StyledRolesGrid = styled.div`
  h3 {
    margin: var(--large) 0 var(--x-small);

  }

  .role-container {
    display: grid;
    gap: var(--x-small);
    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));

    @media (max-width: ${breakpoints.xs}) {
      grid-template-columns: auto;
    }
  }

  .roles-title {
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    text-align: center;
    color: #754ABC;
  }
`;

interface RolesGridProps {
  teamTitle: string;
  roles: RoleType[];
}

const RolesGrid = ({ teamTitle, roles }: RolesGridProps) => (
  <StyledRolesGrid>
    <h3 className="roles-title h3">{teamTitle}</h3>
    <div className="role-container">
      {roles.map((role) => (
        <Role key={role.id} {...role} />
      ))}
    </div>
  </StyledRolesGrid>
);

RolesGrid.propTypes = {
  teamTitle: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    descriptionPlain: PropTypes.number.isRequired,
  })).isRequired,
};

export default RolesGrid;
