import * as React from "react";

import styled from "@emotion/styled";
import AllTeamsIcon from "../../assets/svgs/careers/all-teams.svg";
import CustomerSuccessIcon from "../../assets/svgs/careers/customer-success.svg";
import EngineeringIcon from "../../assets/svgs/careers/engineering.svg";
import GaIcon from "../../assets/svgs/careers/g-a.svg";
import MarketingIcon from "../../assets/svgs/careers/marketing.svg";
import ProductIcon from "../../assets/svgs/careers/product.svg";
import RecruitingIcon from "../../assets/svgs/careers/recruiting.svg";
import SalesIcon from "../../assets/svgs/careers/sales.svg";
import FilterButton from "./filter-button";
import { breakpoints } from "../../styles/pages/breakpoints";

export const teamNameToIconMap: { [type: string]: string } = {
  Engineering: EngineeringIcon,
  Sales: SalesIcon,
  Marketing: MarketingIcon,
  "Customer Success": CustomerSuccessIcon,
  "G&A": GaIcon,
  Product: ProductIcon,
  "Talent Network": AllTeamsIcon,
  Recruiting: RecruitingIcon,
};

export type TeamsFilterProps = {
  teamNames: string[],
  handleFilterValChange: (string) => void,
  isTitleShown?: boolean;
  filterVal: string,
} & React.HTMLAttributes<HTMLDivElement>;

const StyledTeamsFilter = styled.div`
  position: relative;
  padding: var(--nano);
  background: var(--white);
  box-shadow: 2.74034px 2.74034px 13.7017px rgba(0, 0, 0, 0.101961);
  z-index: 1000;
  .h4 {
    color: #484848;
    font-weight: 400;
    font-size: 30px;
    line-height: 44px;
    text-align:center;
  }
  p {
    margin: var(--micro) 0;
  }

  .buttons-grid {
    display: grid;
    grid-row-gap: var(--nano);
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    justify-content: center;

    @media (max-width: ${breakpoints.md}) {
      grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
    }

    @media (max-width: ${breakpoints.xs}) {
      grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    }

    @media (max-width: ${breakpoints["2xs"]}) {
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    }
  }
 &.text-style{
  .h4{
    color:#754ABC;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
  }
 }

  &.bg-border {
    &::before,
    &::after {
      // position: absolute;
      content: "";
      // display: block;
      // height: 2px;
      // top: 50%;
      // // width: 100vw;
      // // background: var(--dark-blue);
      // opacity: 0.1;

      @media (max-width: ${breakpoints["2xs"]}) {
        display: none;
      }
    }

    &::before {
      left: 0;
      transform: translateX(-100%);
    }

    &::after {
      right: 0;
      transform: translateX(100%);
    }
  }
  
`;

const TeamsFilter = ({
  teamNames,
  handleFilterValChange,
  filterVal,
  ...rest
}: TeamsFilterProps) => (
  <StyledTeamsFilter {...rest}>
    <p className="h4">Open roles</p>
    <div className="buttons-grid">
      {teamNames.sort().map((t) => (
        <FilterButton
          key={t}
          teamName={t}
          filterVal={filterVal}
          handleFilterValChange={handleFilterValChange}
        />
      ))}
    </div>
  </StyledTeamsFilter>
);

export default TeamsFilter;
