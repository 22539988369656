import React from "react";
import styled from "@emotion/styled";
import LocationIcon from "../../assets/svgs/careers/location.svg";
import TeamIcon from "../../assets/svgs/careers/team.svg";
import CommitmentIcon from "../../assets/svgs/careers/commitment.svg";
import { breakpoints } from "../../styles/pages/breakpoints";

const StyledRole = styled.a`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 2.74034px 2.74034px 13.7017px rgba(0, 0, 0, 0.101961);
  border-radius: 0rem;
  transition: all 0.2s;

  .role-header {
    font-size: var(--tag-line);
    font-weight: 800;
    text-transform: uppercase;
    padding: var(--milli) var(--x-small);
    border-bottom: var(--border-ultra-light);
    color: var(--mid-gray);

    @media (max-width: ${breakpoints.sm}) {
      display: flex;
      flex-direction: column;

      & > *:not(:last-child) {
        margin-bottom: var(--milli);
      }
    }

    @media (max-width: ${breakpoints["2xs"]}) {
      padding: var(--milli);
    }
  }

  span {
    display: inline-flex;
    align-items: center;
    letter-spacing: 0.1em;
    line-height: 1;

    &:not(:last-of-type) {
      margin-right: var(--x-small);
    }
  }

  img {
    display: block;
    height: 13px;
    width: 13px;
    margin-right: var(--nano);
  }
  .find {
    color: #595E5E;
    .arrow {
      border-color: #595E5E;
    }
  }
  .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: var(--milli) var(--small);

    .top {
      flex: 1;
    }

    @media (max-width: ${breakpoints["2xs"]}) {
      padding: var(--milli);
    }
  }

  h4 {
    color: var(--purple);
  }

  p {
    margin: var(--milli) 0;
  }

  &:hover,
  &:focus {
    background: var(--white);
    box-shadow: 0px 21px 60px rgba(32, 36, 115, 0.07);
    text-decoration: none;

    .find {
      text-decoration: none;

    }
  }
`;

interface Categories {
  location: string;
  team: string;
  commitment: string;
}

export interface BaseRoleProps {
  categories: Categories;
  text: string;
  hostedUrl: string;
}

export interface RoleProps extends BaseRoleProps {
  id?: string;
  descriptionPlain?: string;
}

const Role = ({ categories, text, hostedUrl }: BaseRoleProps) => (
  <StyledRole href={hostedUrl} target="_blank" rel="noreferrer">
    <div className="role-header">
      <span>
        <img src={LocationIcon} alt="Location" /> {categories.location}
      </span>
      <span>
        <img src={TeamIcon} alt="Team" /> {categories.team}
      </span>
      <span>
        <img src={CommitmentIcon} alt="Commitment" /> {categories.commitment}
      </span>
    </div>
    <div className="text">
      <div className="top mb-2">
        <h4 className="h4">{text}</h4>
      </div>
      <div className="find">Find out more
      <i className='arrow right-side' />
      </div>
    </div>
  </StyledRole>
);

export default Role;
