import * as React from "react";
import styled from "@emotion/styled";
import Section from "../section";
import { igniteFeatures } from "../../contents/careers";
import IgniteFeature from "./ignite-feature";
import { breakpoints } from "../../styles/pages/breakpoints";

const StyledIgnite = styled.div`
  margin-bottom: 7.5rem;
  background-color: #F7F8FC;
  .intro {
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    .h2 {
      font-size:40px;
      line-height: 56px;
      font-weight:400;
      color:#754ABC;
    }
    p {
      font-size: 19px;
      line-height:35px;
      font-weight: 400;
      color: var(--gray);
      margin-top: var(--small);
    }
  }

  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: calc(-1 * var(--medium));

    @media (max-width: ${breakpoints.xl}) {
      justify-content: space-around;
    }

    @media (max-width: ${breakpoints.sm}) {
      margin-top: calc(-1 * var(--micro));
    }
  }
`;

const Ignite = () => (
  <Section style={{ backgroundColor: '#F7F8FC' }} >
    <StyledIgnite>
      <div className="wrapper">
        <div className="intro">
          <h2 className="h2">Igniting your best self</h2>
          <p>
            At LogRocket, we believe your talent and achievements deserve to be
            met with a supportive community and flexible environment.
          </p>
        </div>
        <div className="features">
          {igniteFeatures.map((feature) => (
            <IgniteFeature key={feature.title} {...feature} />
          ))}
        </div>
      </div>
    </StyledIgnite>
  </Section>
);

export default Ignite;
