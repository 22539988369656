import * as React from "react";

import styled from "@emotion/styled";
import Section from "../section";
import TeamsFilter, { TeamsFilterProps } from "./teams-filter";
import { breakpoints } from "../../styles/pages/breakpoints";

const StyledOurMission = styled(Section)`
  text-align: center;
  
  .nobr { 
      white-space:nowrap; 
    }

  .teams-filter-container {
    margin: calc(-2 * var(--x-large)) auto;

    @media (max-width: ${breakpoints.sm}) {
      margin: calc(-2 * (var(--xx-small) + var(--micro))) 0 var(--large);
    }

    @media (max-width: ${breakpoints["2xs"]}) {
      margin: calc(-2 * (var(--medium) + var(--milli))) 0 var(--small);
    }

    a {
      text-decoration: none;
    }

    button {
      pointer-events: none;
    }

    & > * {
      margin: var(--small) 0;
    }
    
  }

  .sub-text {
    margin-bottom: var(--micro);
    color: var(--purple);
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
  }
  .h2 {
    font-size: 24px;
    line-height: 50px;
    color: #595E5E
  }
`;

const OurMission = ({
  teamNames,
  handleFilterValChange,
  filterVal,
}: TeamsFilterProps) => (
  <StyledOurMission style={{ background: "#F7F8FC" }}>
    <div className="wrapper">
      <div className="teams-filter-container">
        <a href="#open-roles">
          <TeamsFilter
            filterVal={filterVal}
            teamNames={teamNames}
            handleFilterValChange={handleFilterValChange}
            isTitleShown={true}
          />
        </a>
      </div>
      <header>
        <div className="sub-text h4">OUR MISSION</div>
        <h2 className="h2">
          Empower software teams to create the ideal web and <span className="nobr">mobile product experience.</span>
        </h2>
      </header>
    </div>
  </StyledOurMission>
);

export default OurMission;
