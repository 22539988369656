import React from "react";

import styled from "@emotion/styled";
import { breakpoints } from "../styles/pages/breakpoints";

const StyledSection = styled.section`
  padding: var(--huge) 0;

  @media (max-width: ${breakpoints.sm}) {
    padding: var(--medium) 0;
  }

  @media (max-width: ${breakpoints["2xs"]}) {
    padding: var(--small) 0;
  }
`;

type SectionProps = {
  children: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
} & React.HTMLAttributes<HTMLDivElement>

const Section = ({ children, style, ...rest }: SectionProps) => (
  <StyledSection style={style} {...rest}>
    {children}
  </StyledSection>
);

export default Section;
