import * as React from "react";

import styled from "@emotion/styled";
import Section from "../section";
import { breakpoints } from "../../styles/pages/breakpoints";
import Achievements from "./achievements";
import rightCircles from '../../assets/svgs/careers/right-circles.svg';
import leftCircles from '../../assets/svgs/careers/left-circles.svg';

const StyledWhyJoin = styled.div`

position:relative;
over-flow: hidden;
background-color: #F7F8FC;
&::before {
  content:"";
  display:none;
  background-image: url(${leftCircles});
  position: absolute;
  left: 0px;
  top: -20px;
  width: 50%;
  z-index: 0;
  height: 310px;
  background-repeat: no-repeat;
  
}
&::after {
  content:"";
  display: none;
  background-image: url(${rightCircles});
  position: absolute;
  right: 0px;
  top: 24%;
  width: 35%;
  z-index: 0;
  height: 57%;
  background-repeat: no-repeat;
  background-size: cover;
}
.StyledWhyJoin {
background: #FFFFFF;
box-shadow: 5.27093px 5.27093px 26.3547px rgba(0, 0, 0, 0.101961);
padding: 115px 65px 90px 65px;
  .text-box {
    display: flex;
    justify-content: space-between;

    @media (max-width: ${breakpoints.md}) {
      flex-direction: column-reverse;
      max-width: var(--wrapper-x-small);
      margin: auto;

      & > :last-child {
        margin-bottom: var(--small);
      }
    }

    & > :first-child {
      flex: 0 0 42%;
    }

    & > :last-child {
      flex: 0 0 50%;
    }
  }

  .h2 {
    font-size: 40px;
    line-height 44px;
    color: #754ABC;
    margin-bottom: var(--xx-small);
    }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    position: relative;
    padding-left: calc(var(--xx-small) + 0.375rem);

    @media (max-width: ${breakpoints["2xs"]}) {
      padding-left: calc(var(--micro) + 0.375rem);
    }

    &::before {
      position: absolute;
      top: var(--micro);
      left: 0;
      content: "";
      display: block;
      height: 0.375rem;
      width: 0.375rem;
      background: var(--dark-blue);
      border-radius: 50%;
    }

    &:not(:last-child) {
      margin-bottom: var(--xx-small);
    }
  }
 
}
@media (max-width: ${breakpoints.sm}) {
  &::before{
    display: none;

  }
  &::after{
    display: none;

  }
  .StyledWhyJoin {
    padding: 50px 20px;
  }

}
`;

const WhyJoin = () => (
  <StyledWhyJoin>
    <div className="wrapper">
      <Section style={{ position: 'relative', zIndex: '999' }}>
        <div className="StyledWhyJoin">
          <h2 className="h2">Why join LogRocket?</h2>
          <div className="text-box">
            <div>
              <ul>
                <li>
                  With state-of-the-art session replay and monitoring technology,
                  LogRocket gives engineering and product teams unbridled access
                  to see how users interact with their product and insight into
                  how to <strong>improve each and every user experience</strong>
                </li>
                <li>
                  LogRocket is backed by <strong>leading VCs</strong> such as
                  Matrix Partners and Battery Ventures, and has experienced
                  tremendous growth since launching in 2016
                </li>
                <li>
                  Our team brings a diverse set of <strong>experiences and perspectives,</strong>{" "}
                  and is committed to being a pillar in the growing tech community
                </li>
              </ul>
            </div>
            <div>
              Building a world-class product is only possible if we continue to
              build a <strong>world-class team</strong>, which is why we dedicate
              just as much time and resources into growing our organization and
              culture. We focus on building our software with <strong>care and craftsmanship</strong>{" "}
              and we welcome talented people from all backgrounds.
            </div>
          </div>

          <Achievements />
        </div>
      </Section>
    </div>
  </StyledWhyJoin>
);

export default WhyJoin;
