import * as React from "react";
import styled from "@emotion/styled";
import Section from "../section";
import { posts } from "../../contents/careers";
import RecentPost from "./recent-post";
import { breakpoints } from "../../styles/pages/breakpoints";

const StyledRecentBlogPosts = styled.div`
  h2 {
    text-align: center;
  }

  .posts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: var(--medium);

    @media (max-width: ${breakpoints.sm}) {
      margin-bottom: var(--x-small);
    }

    @media (max-width: ${breakpoints.xl}) {
      justify-content: space-around;
    }
  }

  .btn-wrapper {
    text-align: center;
  }
`;

const RecentBlogPosts = () => (
  <Section style={{ background: "#F7F8FC", paddingTop: 0 }}>
    <StyledRecentBlogPosts className="wrapper">
      {/* <h2 className="h2">Recent blog posts</h2> */}
      <div className="posts">
        {posts.map((post) => (
          <RecentPost {...post} key={post.title} />
        ))}
      </div>
      {/* <div className="btn-wrapper">
        <a href="https://blog.logrocket.com/" className="btn btn-primary">
          Read more posts
        </a>
      </div> */}
    </StyledRecentBlogPosts>
  </Section>
);

export default RecentBlogPosts;
