import * as React from "react";

import styled from "@emotion/styled";
import { achievements } from "../../contents/careers";
import Investors from "../../assets/images/careers/logrocket-investors.png";
import { breakpoints } from "../../styles/pages/breakpoints";

const StyledAchievements = styled.div`
  padding-top: var(--huge);

  @media (max-width: ${breakpoints.sm}) {
    padding-top: var(--small);
  }

  @media (max-width: ${breakpoints.xs}) {
    padding-top: 0;
  }

  .achievements {
    display: flex;
    text-align: center;

    @media (max-width: ${breakpoints.sm}) {
      flex-direction: column;
    }
  }

  .achievement {
    flex: 0 0 25%;
    padding: 0 var(--small);

    @media (max-width: ${breakpoints.md}) {
      padding: 0 var(--xx-small);
    }

    @media (max-width: ${breakpoints.sm}) {
      padding: var(--small) var(--medium);
      width: 270px;
      max-width: 360px;
      margin: 0 auto;
    }

    @media (max-width: ${breakpoints.xs}) {
      padding: var(--xx-small) var(--medium);
    }

    &:not(:last-child) {
      border-right: 2px solid var(--white-gray);

      @media (max-width: ${breakpoints.sm}) {
        border-right: none;
        border-bottom: 2px solid var(--white-gray);
      }
    }
  }

  .h1 {
    color: var(--purple);
    margin-bottom: var(--micro);
  }

  .h4 {
    color: var(--mid-gray);
  }

  img {
    display: block;
    width: 670px;
    margin: var(--x-large) auto 0;

    @media (max-width: ${breakpoints.xs}) {
      margin: 0 auto;
    }
  }
`;

const Achievements = () => (
  <StyledAchievements>
    <div className="achievements">
      {achievements.map(({ title, text }) => (
        <div className="achievement" key={title}>
          <h3 className="h1">{title}</h3>
          <p className="h4">{text}</p>
        </div>
      ))}
    </div>
    <div>
      <img src={Investors} alt="LogRocket investors" />
    </div>
  </StyledAchievements>
);

export default Achievements;
