import * as React from "react";

// import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
// import CareersHeroImg from "../../assets/images/careers/careers-hero.png";
// import { breakpoints } from "../../styles/pages/breakpoints";
// import BgLeft from "../../assets/svgs/careers/bg-left.svg";
// import BgRight from "../../assets/svgs/careers/bg-right.svg";
// import Bgimg from '../../assets/images/pricing-page-hero-bg.png';
import SanityHeroComponentBlock from "../blocks/SanityHeroComponent";

// const Bg = styled.div`
//   position: relative;

//   .bg {
//     position: absolute;
//     bottom: 0;

//     @media (max-width: ${breakpoints.xl}) {
//       display: none;
//     }

//     &--left {
//       left: 1%;
//       max-height: 350px;

//       @media (max-width: ${breakpoints["2xl"]}) {
//         left: 0;
//         max-height: 300px;
//       }
//     }

//     &--right {
//       right: -3px;
//       max-width: 400px;

//       @media (max-width: ${breakpoints["2xl"]}) {
//         max-width: 300px;
//       }
//     }
//   }
// `;

// const StyledHero = styled.div`
//   max-width: var(--wrapper-small);

//   .banner {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: var(--x-huge) 0 calc(var(--x-huge) + var(--large));
//     margin: auto;

//     @media (max-width: ${breakpoints.sm}) {
//       flex-direction: column-reverse;
//       padding: var(--x-huge) 0 var(--x-large);
//     }

//     & > * {
//       flex: 0 0 48%;
//     }
//   }

//   .h1 {
//     margin-bottom: var(--small);

//     @media (max-width: ${breakpoints.sm}) {
//       margin-bottom: 0;
//     }

//     span {
//       color: var(--purple);
//     }
//   }

//   .img-container-desktop {
//     @media (max-width: ${breakpoints.sm}) {
//       display: none;
//     }
//   }

//   .img-container-mobile {
//     display: none;
//     @media (max-width: ${breakpoints.sm}) {
//       display: block;
//       margin-bottom: var(--xx-small);
//     }
//   }

//   img {
//     display: block;
//     max-width: 368px;
//     max-height: 368px;
//     width: 100%;

//     @media (max-width: ${breakpoints.sm}) {
//       max-width: 300px;
//     }
//   }
// `;

const Hero = () => {
 const carrierHeroData = useStaticQuery(graphql`
  query HeroPage {
    sanityHeroComponent(internalName: {eq: "Careers page-hero component"}) {
      ...SanityHeroComponent
    }
  }`)
return <SanityHeroComponentBlock {...carrierHeroData?.sanityHeroComponent}/>
};

export default Hero;
