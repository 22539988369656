import styled from "@emotion/styled";
import * as React from "react";
import { breakpoints } from "../../styles/pages/breakpoints";

export interface IgniteFeatureProps {
  icon: string;
  title: string;
  text: string;
}

const StyledIgniteFeature = styled.div`
  max-width: 368px;
  min-width: 280px;
  flex: 0 0 30%;
  margin-top: var(--x-large);

  @media (max-width: ${breakpoints.sm}) {
    margin-top: var(--x-small);
  }

  img {
    height: 60px;
    width: 60px;
  }
  p {
    font-size: 19px;
    line-height: 25px;
  }
  .h3 {
    margin: var(--x-small) 0;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    color: #754ABC;
   

    @media (max-width: ${breakpoints.sm}) {
      margin-bottom: var(--micro);
    }
  }
`;

const IgniteFeature = ({ icon, title, text }: IgniteFeatureProps) => (
  <StyledIgniteFeature>
    <img src={icon} alt="title" />
    <h3 className="h3">{title}</h3>
    <p>{text}</p>
  </StyledIgniteFeature>
);

export default IgniteFeature;
