import * as React from "react";
import styled from "@emotion/styled";
import Section from "../section";
import RolesGrid from "./roles-grid";
import TeamsFilter from "./teams-filter";
import { Role } from "../../types/careers"
import { breakpoints } from "../../styles/pages/breakpoints";

type RolesByTeam = {
  team: string;
  entries: Role[]
}
interface OpenRolesProps {
  filterVal: string;
  teamNames: string[];
  handleFilterValChange: (string) => void;
  generateRolesToRender: (a: RolesByTeam[]) => RolesByTeam[];
  categorizedRoles: RolesByTeam[]
}

const StyledOpenRoles = styled.div`
  h2 {
    text-align: center;
    margin-bottom: var(--small);
  }
  .open-role-container {
    margin: -16% auto 0 auto;
    @media (max-width: ${breakpoints.md}) {
      margin: -25% auto 0 auto
      }
    @media (max-width: ${breakpoints.sm}) {
    margin: -19% auto 0 auto
    }

    @media (max-width: ${breakpoints["2xs"]}) {
      margin: calc(-2 * (var(--medium) + var(--milli))) 0 var(--small);
    }
  }
`;

const OpenRoles = ({
  filterVal,
  teamNames,
  handleFilterValChange,
  generateRolesToRender,
  categorizedRoles,
}: OpenRolesProps) => (
  <Section id="open-roles" style={{ background: "#F7F8FC" }}>
    <StyledOpenRoles className="wrapper">
      <div className="open-role-container">
      <TeamsFilter
        filterVal={filterVal}
        teamNames={teamNames}
        handleFilterValChange={handleFilterValChange}
        className="text-style"
      />
      </div>
      <div>
        {generateRolesToRender(categorizedRoles).map((r) => (
          <RolesGrid key={r.team} teamTitle={r.team} roles={r.entries} />
        ))}
      </div>
    </StyledOpenRoles>
  </Section>
);

export default OpenRoles;
