import * as React from "react";
import styled from "@emotion/styled";
import { teamNameToIconMap } from "./teams-filter";
import GenericIcon from "../../assets/svgs/careers/teams-generic.svg";
import { breakpoints } from "../../styles/pages/breakpoints";

export interface FilterButtonProps {
  filterVal: string;
  teamName: string;
  handleFilterValChange: (string) => void;
}

const StyledFilterButton = styled.button`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: var(--milli) var(--nano);
  margin: auto;
  width: 99%;
  color: var(--dark-blue);
  background: none;
  border: none;
  border-radius: 0.75rem;
  transition: background 0.2s;

  @media (min-width: calc(${breakpoints.md} + 1px)) {
    height: 10rem;
  }

  &:hover,
  &:focus,
  &:active,
  &.selected {
    background: var(--off-white);
  }

  &.selected {
    text-shadow: 0 0 1px var(--dark-blue);
  }

  .teamName {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #754ABC;
  }
  img {
    height: 60px;
    width: 60px;
    margin-bottom: var(--milli);
  }
`;

const FilterButton = ({
  filterVal,
  teamName,
  handleFilterValChange,
}: FilterButtonProps) => (
  <StyledFilterButton
    type="button"
    onClick={() => handleFilterValChange(teamName)}
    className={teamName === filterVal && "selected"}
  >
    <img src={teamNameToIconMap[teamName] || GenericIcon} alt={teamName} />
    <div className="teamName">{teamName}</div>
  </StyledFilterButton>
);

export default FilterButton;
