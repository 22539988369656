import * as React from 'react';

import { graphql, PageProps } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/careers/hero';
import WhyJoin from '../components/careers/why-join';
import OurValues from '../components/careers/our-values';
import RecentBlogPosts from '../components/careers/recent-blog-posts';
import Ignite from '../components/careers/ignite';
import OpenRoles from '../components/careers/open-roles';
import OurMission from '../components/careers/our-mission';

const CareersPage = ({ data }: PageProps<Queries.CareersDataQuery>) => {
  const [filterVal, setFilterVal] = React.useState('');

  const handleFilterValChange = (newVal) => {
    setFilterVal(newVal);
  };

  const generateRolesToRender = (categorizedRoles) =>
    filterVal === ''
      ? categorizedRoles
      : categorizedRoles.filter((c) => c.team === filterVal);

  const roles = data.allLever.edges.map((r) => r.node);
  const teamNames: string[] = Array.from(
    new Set(roles.map((i) => i.categories.team))
  );

  const categorizedRoles = teamNames.map((t) => ({
    team: t,
    entries: roles.filter((r) => r.categories.team === t),
  }));

  return (
    <Layout>
      <SEO title='LogRocket | Careers' description={['Browse LogRocket open positions']} />
      <Hero />
      <OurMission
        filterVal={filterVal}
        teamNames={teamNames}
        handleFilterValChange={handleFilterValChange}
      />
      <WhyJoin />
      <OurValues />
      <RecentBlogPosts />
      <Ignite />
      <OpenRoles
        filterVal={filterVal}
        teamNames={teamNames}
        handleFilterValChange={handleFilterValChange}
        generateRolesToRender={generateRolesToRender}
        categorizedRoles={categorizedRoles}
      />
    </Layout>
  );
};

export const query = graphql`
  query CareersData {
    allLever {
      edges {
        node {
          id
          text
          hostedUrl
          descriptionPlain
          categories {
            commitment
            location
            team
          }
        }
      }
    }
  }
`;

export default CareersPage;
